/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";

import './Works.css';

// Import ../../assets/recentprojects/
import HipTrain from '../../assets/recentprojects/hiptrain.png';
import Roma from '../../assets/recentprojects/roma.png';
import Checkinlove from '../../assets/recentprojects/checkinlove.png';
import MundoJaguar from '../../assets/recentprojects/mundojaguar.png';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: '3em',
    marginBottom: "auto",
  },
}));

export const Works = () => {
  const classes = useStyles();
  const [projects, setProjects] = useState([

    {
      id: 1,
      title: 'UltharCR',
      description: `Online market for various economic and artistic iniatives.`,
      alter: 'Ulthar Project',
      image: `${MundoJaguar}`,
      url: `https://ulthar.betextech.com/`
    },
    {
      id: 2,
      title: 'Roma Invicta',
      description: `Historical project about an interactive map based on Three.js with ancient events focused mainly on Ancient Rome.`,
      alter: 'Roma Project',
      image: `${Roma}`,
      url: `http://roma.betextech.com/`
    },
    {
      id: 3,
      title: 'HipTrain',
      description: `Web and mobile personal training app based in React and Amazon Services.`,
      alter: 'HipTrain Project',
      image: `${HipTrain}`,
      url: `https://apps.apple.com/us/app/hiptrain-personal-training/id1598403186`
    },
    {
      id: 4,
      title: 'Checkinlove',
      description: `Fundraiser website for newlyweds.`,
      alter: 'Checkinlove Project',
      image: `${Checkinlove}`,
      url: `https://www.checkinlove.com/`
    },
  ]);

  return (
    <section id="works">
      <Container component="main" className={classes.main} maxWidth="md">
        {projects.map((project) => (
          <div className="project" key={project.id}>
            <div className="__img_wrapper">
              <img src={project.image} alt={project.alter} />
            </div>
            <div className="__content_wrapper">
         <h3 className="title">
                <TextDecrypt text={project.id + '. ' + project.title} />
              </h3>
              <p >
                {project.description}
              </p>
              <a href={project.url} target="_blank" rel="noreferrer" className="description">Check it out</a>
            </div>
          </div>
        ))}
      </Container>
    </section>
  );
};
